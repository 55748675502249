import {
  A,
  Attachment,
  BluePencil,
  botRed,
  ButtonDelete,
  Camera,
  CloseXRed,
  girl,
  Link,
  MicBlue,
  SixPoints,
  Smile,
  ThreePointsBlue
} from "../../../../assets";
import {
  Container,
  Baloon,
  BotName,
  BotNumberId,
  ButtonContainer,
  ButtonPencil,
  ButtonPlus,
  ButtonTrash,
  Card,
  CardCenter,
  CardContentAvatar,
  CardRoundedAvatar,
  ContainerFields,
  ContainerImg,
  ContainerLeftModal,
  ContainerRightModal,
  CreatedBycss,
  Delete,
  Divisor,
  Flex,
  Identification,
  ImageSixPoints,
  ImgBluePencil,
  InputContainer,
  LabelContainerFields,
  LeftHeaderWhiter,
  MyIconButton,
  Signal,
  Span,
  Token,
  ImgIcon
} from "./styles";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { LetterCircle } from "../LetterCircle";
import { setToast } from "../../../../redux/slices/user";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  setActive,
  setBotAvatarColor,
  setBotChatColor,
  setBotEmailOnStart,
  setBotNameOnStart,
  setBotPhoneOnStart,
  setBotServices,
  setBotTransferId,
  setBotWhatsappRedirect,
  setFirstBotInteractions,
  setName,
  setTarget,
  setWorkspaceId,
  setWorkspaceList
} from "../../../../redux/slices/bot";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import WorkspaceService from "../../../../services/WorkspaceService";
import { Text } from "../../../../components/Text";
import { InputColor } from "../../../../components/InputColor";
import { BotPreview } from "../../../../components/BotPreview";
import { ChatPreview } from "../../../../components/ChatPreview";
import Input from "../../../../components/input-text";
import Button from "../../../../components/button";
import BotsServices from "../../../../services/bots";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { InputTextarea } from "primereact/inputtextarea";
import { IBotInteractions } from "../../../../types/iBotResume";
import { InputText } from "primereact/inputtext";
import * as comunCss from "../../../comum_css";
import { Tooltip } from "primereact/tooltip";
import { setEmojiModalOpen } from "../../../../redux/slices/flowInteractions";
import { EmojiModal } from "../../../Interactions/components/interacaoRobo/EmojiModal";

interface IBotActiveObject {
  name: string;
  code: boolean;
}
interface IBotId {
  id: number;
  name: string;
  active: IBotActiveObject;
  workspace: number;
  services: number;
  createdBy: string;
}
interface IServicesObject {
  name: string;
  code: number;
}

export const BotId: React.FC<IBotId> = ({
  id,
  name,
  active,
  // workspace,
  services,
  createdBy
}) => {
  const dispatch = useAppDispatch();
  const botResume = useAppSelector((state) => state.bot);
  const [selectedState, setSelectedState] = useState<IBotActiveObject>(active);
  const [displayModal, setDisplayModal] = useState(false);
  const [workspaceLevel, setWorkspaceLevel] = useState([]);
  const [avatarColor, setAvatarColor] = useState(botResume?.avatarColor);
  const [chatColor, setChatColor] = useState(botResume?.chatColor);
  const [botName, setBotName] = useState(botResume?.name);
  const [botToken, setBotToken] = useState(botResume?.token);
  const [botTarget, setBotTarget] = useState(botResume?.target);
  const [habilitarNome, setHabilitarNome] = useState<boolean>(false);
  const [habilitarTelefone, setHabilitarTelefone] = useState<boolean>(false);
  const [habilitarEmail, setHabilitarEmail] = useState<boolean>(false);
  const [whatsappRedirect, setWhatsappRedirect] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const emoji = useAppSelector(
    (state) => state.flowInteractions.valueTextEmoji
  );
  const [cursorPosition, setCursorPosition] = useState<number>(0);
  const [firstBotMessages, setFirstBotMessages] = useState<IBotInteractions[]>([
    {
      position: 1,
      interaction: ""
    }
  ]);
  const chatbots = useAppSelector((state) => state.chatbots);
  const chatbotsAvailable = chatbots.chatbots.filter((el) => el.id !== id);
  const [valueTarget, setValueTarget] = useState("");
  const { UpdateAvatar } = BotsServices();
  const counterToast = useAppSelector(
    (state) => state.user.toastApp?.count || 0
  );
  const valueToken = useAppSelector((state) => state.bot.token);
  const [valueIdWorkspace, setValueIdWorkspace] = useState(0);
  const handleSalvar = async () => {
    try {
      const retorno = await UpdateAvatar(botResume.id, {
        avatarColor,
        chatColor,
        name: botName,
        interactions: firstBotMessages,
        nameOnStart: habilitarNome,
        phoneOnStart: habilitarTelefone,
        emailOnStart: habilitarEmail,
        whatsappRedirect
      });
      dispatch(setBotAvatarColor(avatarColor));
      dispatch(setBotChatColor(chatColor));
      dispatch(setName(botName));
      dispatch(setBotNameOnStart(habilitarNome));
      dispatch(setBotEmailOnStart(habilitarEmail));
      dispatch(setBotPhoneOnStart(habilitarTelefone));
      dispatch(setBotWhatsappRedirect(whatsappRedirect));
      dispatch(setFirstBotInteractions(firstBotMessages));
      setDisplayModal(false);
    } catch (error) {
      dispatch(
        setToast({
          count: counterToast + 1,
          show: true,
          life: 3000,
          severity: "error",
          summary: "Erro ao atualizar dados de Avatar.",
          detail: "Por favor tente mais tarde.",
          sticky: false
        })
      );
    }
  };

  const serviceOptions = [
    { name: "Atendimento", code: 1 },
    { name: "Suporte", code: 2 },
    { name: "FAQ", code: 3 },
    { name: "Funcionário digital", code: 4 },
    { name: "Campanha", code: 5 },
    { name: "Sem script", code: 6 }
  ];

  const initialServiceCode = 1;

  const [Services, setServices] = useState<IServicesObject>({
    name: serviceOptions.find((s) => s.code === initialServiceCode)?.name || "",
    code: initialServiceCode
  });

  // const workSP = useContext(WorkspaceContext);
  const { getWorkspaceByCompanyId } = WorkspaceService();
  const { user } = useAuth() as AuthContextType;
  // const { isObjectEmpty } = functions();
  // useEffect(() => {
  //   setSelectedState(active);
  // }, [active.code]);
  const states = [
    { name: "Ativo", code: true },
    { name: "Inativo", code: false }
  ];

  useEffect(() => {
    setBotName(botResume?.name);
    setChatColor(botResume?.chatColor);
    setAvatarColor(botResume?.avatarColor);
    setFirstBotInteractions(botResume?.interactions);
    setHabilitarNome(botResume?.nameOnStart);
    setHabilitarTelefone(botResume?.phoneOnStart);
    setHabilitarEmail(botResume?.emailOnStart);
    setWhatsappRedirect(botResume?.whatsappRedirect);
    setBotTransferId(botResume?.botTransferId || -1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayModal]);
  const onStateChange = (e: { value: { code: boolean; name: string } }) => {
    dispatch(setActive(e.value.code));
    setSelectedState(e.value);
  };

  const onServiceChange = (e: { value: { code: number; name: string } }) => {
    dispatch(setBotServices(e.value.code));
    dispatch(setTarget(e.value.code));
    setServices(e.value);
    setBotTarget(e.value.code);

    switch (e.value.code) {
      case 1:
        setValueTarget("Atendimento");
        break;
      case 2:
        setValueTarget("Suporte");
        break;
      case 3:
        setValueTarget("FAQ");
        break;
      case 4:
        setValueTarget("Funcionário digital");
        break;
      case 5:
        setValueTarget("Campanha");
        break;
      case 6:
        setValueTarget("Sem script");
        break;
      default:
        break;
    }
  };
  const selectedStateTemplate = (
    option: { name: string; code: boolean },
    props: { placeholder?: string }
  ) => {
    if (option) {
      return (
        <div className="flex align-items-center h-2rem w-full">
          <Signal color={option.code === true ? "#6FCF97" : "#801515"} />
          <div>{option.name}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };
  const handleChangeAvatarColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAvatarColor(e.target.value);
  };
  const handleChangeChatColor = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChatColor(e.target.value);
  };
  const onChangeWorkspace = (e: number) => {
    dispatch(setWorkspaceId(e));
  };
  const onChangeBotTransferId = (e: number) => {
    dispatch(setBotTransferId(e));
  };

  useEffect(() => {
    const companyData = localStorage.getItem("company");

    if (user.admin && companyData) {
      const company = JSON.parse(companyData);
      setValueIdWorkspace(company.id);
    } else {
      setValueIdWorkspace(user.companyId);
    }
  }, [user.admin, user.companyId]);

  useEffect(() => {
    if (valueIdWorkspace) {
      (async () => {
        const wokspaceList = await getWorkspaceByCompanyId(valueIdWorkspace);
        setWorkspaceLevel(wokspaceList);
        dispatch(setWorkspaceList(wokspaceList));
      })();
    }
  }, [valueIdWorkspace]);

  const handleClose = () => {
    setBotName(botResume?.name);
    setAvatarColor(botResume?.chatColor);
    setChatColor(botResume?.avatarColor);
    setHabilitarNome(botResume?.nameOnStart);
    setHabilitarTelefone(botResume?.phoneOnStart);
    setHabilitarEmail(botResume?.emailOnStart);
    setWhatsappRedirect(botResume?.whatsappRedirect);
    setFirstBotMessages(botResume?.interactions);
    setBotTransferId(botResume?.botTransferId || -1);
  };
  const stateOptionTemplate = (option: { code: boolean; name: string }) => {
    return (
      <div className="flex align-items-center h-2rem ">
        <Signal color={option.code ? "#6FCF97" : "#801515"} />
        <div>{option.name}</div>
      </div>
    );
  };
  const handleSetFirstBotMessages = (message: string, position: number) => {
    const oldFirstBotMessages = firstBotMessages;
    const newFirstBotMessages = oldFirstBotMessages?.map((el) => {
      if (el.position === position) {
        return {
          position: el.position,
          interaction: message
        };
      }
      return el;
    });
    setFirstBotMessages(newFirstBotMessages);
  };

  useEffect(() => {
    const oldFirstBotMessages = firstBotMessages;
    const newFirstBotMessages = oldFirstBotMessages?.map((el) => {
      const firstValue = el.interaction.slice(0, cursorPosition);
      const lastValue = el.interaction.slice(cursorPosition);
      return {
        position: cursorPosition,
        interaction: `${firstValue}${emoji}${lastValue}`
      };
    });
    setFirstBotMessages(newFirstBotMessages);
  }, [emoji]);

  const handleAddInteraction = () => {
    const maxPosition = firstBotMessages.reduce((acc, el) => {
      if (el.position > acc) {
        return el.position;
      }
      return acc;
    }, 0);
    const newFirstMessage = {
      position: maxPosition + 1,
      interaction: ""
    };
    setFirstBotMessages([...firstBotMessages, newFirstMessage]);
  };
  const handleSetOneInteraction = () => {
    setFirstBotMessages([
      {
        position: 1,
        interaction: ""
      }
    ]);
  };

  useEffect(() => {
    if (!firstBotMessages.length) {
      handleSetOneInteraction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstBotMessages]);
  const handleDeleteInteraction = (position: number) => {
    const oldFirstMessages = firstBotMessages;
    const newFirstMessages = oldFirstMessages.filter(
      (el) => el.position !== position
    );
    setFirstBotMessages(newFirstMessages);
  };

  const botTransferItemTemplate = (option: { name: string; id: number }) => {
    return (
      <div className="country-item flex align-items-center justify-content-between">
        <div>{option.name}</div>
        {option.id === botResume?.botTransferId ? (
          // <Button style={{height: "1rem", width: "1rem", fontSize: "10px"}} icon="pi pi-times" className="p-button-rounded p-button-danger p-button-outlined" aria-label="Cancel"  onClick={() => onChangeBotTransferId(-1)} />
          <MyIconButton
            color="#FF2C55"
            onClick={() => onChangeBotTransferId(-1)}
          >
            <img src={CloseXRed} alt="Close Button" />
          </MyIconButton>
        ) : null}
      </div>
    );
  };

  useEffect(() => {
    if (botResume?.target !== undefined) {
      switch (botResume.target) {
        case 1:
          setValueTarget("Atendimento");
          break;
        case 2:
          setValueTarget("Suporte");
          break;
        case 3:
          setValueTarget("FAQ");
          break;
        case 4:
          setValueTarget("Funcionário digital");
          break;
        case 5:
          setValueTarget("Campanha");
          break;
        case 6:
          setValueTarget("Sem script");
          break;
        default:
          break;
      }
    }
  }, [botResume.target]);

  const handleCopy = () => {
    navigator.clipboard.writeText(valueToken);
  };

  return (
    <div css={Container}>
      <div css={Card}>
        <div css={Identification}>
          <div className="flex align-items-center">
            <div css={ContainerImg}>
              <img src={girl} alt="imgBot" />
              <button
                type="button"
                onClick={() => {
                  handleClose();
                  setDisplayModal(!displayModal);
                }}
                css={ButtonPencil}
              >
                <img css={ImgBluePencil} src={BluePencil} alt="imgBot" />
              </button>
            </div>
            <div>
              <div css={BotName}>{name}</div>
              <div css={BotNumberId}>#{id}</div>
            </div>
          </div>
          <div className="flex align-items-center">
            <Dropdown
              style={{ width: "100%" }}
              value={selectedState}
              options={states}
              onChange={onStateChange}
              optionLabel="name"
              placeholder={active.code ? "Ativo" : "Inativo"}
              valueTemplate={selectedStateTemplate}
              itemTemplate={stateOptionTemplate}
            />
          </div>
        </div>
        <div css={Divisor} />
        <div className="flex align-items-center w-full">
          <Dropdown
            style={{
              width: "90%",
              margin: "0 auto",
              marginBottom: "0.7rem",
              height: "48px",
              alignItems: "center"
            }}
            id="nivel"
            options={workspaceLevel}
            optionLabel="name"
            optionValue="id"
            placeholder="Workspace"
            value={botResume?.workspaceId}
            onChange={(e) => onChangeWorkspace(e.value)}
            filter={true}
          />
        </div>
        <div className="flex align-items-center w-full">
          <Dropdown
            style={{
              width: "90%",
              margin: "0 auto",
              marginBottom: "0.7rem",
              height: "48px",
              alignItems: "center"
            }}
            id="nivel"
            options={chatbotsAvailable}
            optionLabel="name"
            optionValue="id"
            itemTemplate={botTransferItemTemplate}
            placeholder="Bot de transferência"
            value={botResume?.botTransferId}
            onChange={(e) => onChangeBotTransferId(e.value)}
            filter={true}
          />
        </div>
        <div className="flex align-items-center w-full">
          <Dropdown
            style={{
              width: "90%",
              margin: "0 auto",
              height: "48px",
              alignItems: "center"
            }}
            value={botTarget}
            options={serviceOptions}
            onChange={onServiceChange}
            optionLabel="name"
            placeholder={valueTarget}
          />
        </div>
        <div css={CreatedBycss}>
          Criado por:
          <LetterCircle initial={createdBy} />
          {createdBy}
        </div>
        <Divisor />
        <Button type="button" disabled={true} css={Delete}>
          DELETAR BOT
        </Button>
      </div>
      <Dialog
        className="addWspaceDialog"
        headerStyle={{
          position: "relative",
          borderTopLeftRadius: "1rem",
          borderTopRightRadius: "1rem",
          border: "none",
          height: "3rem",
          backgroundColor: "#4A6690",
          paddingTop: "25px"
        }}
        visible={displayModal}
        contentStyle={{
          padding: 0,
          height: "694px",
          width: "850px",
          borderBottomLeftRadius: "1rem",
          borderBottomRightRadius: "1rem",
          border: "none",
          overflow: "hidden",
          display: "block"
        }}
        onHide={() => {
          setDisplayModal(false);
          dispatch(setEmojiModalOpen(false));
        }}
        closeOnEscape={true}
        resizable={false}
        closable={true}
        keepInViewport={false}
        draggable={false}
        // contentStyle={{ display: "flex", }}
        // headerStyle={headerStyle}
      >
        <LeftHeaderWhiter />
        <div className="flex h-full flex-end">
          <ContainerLeftModal>
            <EmojiModal heightEmojiPicker="320px" />
            <div className="flex mb-3 align-items-center justify-content-center">
              <img width="10%" src={botRed} alt="bot-image" />
              <div className="ml-2">
                <Text s="15px" lh="20px" weight={500} color="#0A043C">
                  Configurações do seu ChatBot
                </Text>
              </div>
            </div>
            <InputContainer>
              <span className="p-float-label">
                <Input
                  id="label"
                  value={botName}
                  onChange={(e) => setBotName(e.target.value)}
                />
                <label
                  style={{
                    marginTop: "0.46rem",
                    backgroundColor: "#FFF",
                    paddingLeft: "0.3rem",
                    paddingRight: "0.3rem"
                  }}
                  htmlFor="label"
                >
                  Nome do Bot
                </label>
              </span>
            </InputContainer>

            <div
              className="flex bg-white justify-content-start flex-column p-2 mt-4"
              // style={{
              //   borderTop: "1px solid #EEE"
              // }}
            >
              <div
                style={{ border: "3px solid rgba(33, 150, 243, 0.3)" }}
                className="relative mt-2 p-2 w-full border-round-lg"
              >
                <div className="absolute -mt-4 ml-6 bg-white border-round-right-3xl flex">
                  <div className="flex align-items-center">
                    <div className="w-2rem h-2rem -ml-4 shadow-7 text-center border-circle bg-white align-items-center justify-content-center">
                      <img
                        className="my-1"
                        width="19px"
                        src={botRed}
                        alt="Bot-Image"
                      />
                    </div>

                    <div
                      className="ml-1"
                      style={{
                        fontWeight: 400,
                        fontSize: "14px",
                        color: "#046AF3"
                      }}
                    >
                      Interação do robô
                    </div>
                    <Span>?</Span>
                  </div>
                </div>
                {firstBotMessages?.map((firstBotMessage) => (
                  <>
                    <div className="content mt-4 ml-2"></div>
                    <div css={Baloon}>
                      <img
                        css={ImageSixPoints}
                        src={SixPoints}
                        alt="icon-six-points"
                      />
                      <InputTextarea
                        style={{
                          border: "0px",
                          outline: "none",
                          width: "100%",
                          resize: "vertical"
                        }}
                        autoResize={true}
                        onKeyUp={(e) =>
                          setCursorPosition(e.currentTarget.selectionStart)
                        }
                        onClick={(e) =>
                          setCursorPosition(e.currentTarget.selectionStart)
                        }
                        onSelect={(e) =>
                          setCursorPosition(e.currentTarget.selectionStart)
                        }
                        autoFocus={true}
                        value={firstBotMessage.interaction}
                        onChange={(e) =>
                          handleSetFirstBotMessages(
                            e.target.value,
                            firstBotMessage.position
                          )
                        }
                        // onKeyDown={handleKeyPress}
                        placeholder="Insira a primeira mensagem do Bot aqui..."
                      />
                      <button
                        onClick={handleAddInteraction}
                        type="button"
                        css={ButtonPlus}
                      >
                        +
                      </button>
                      {firstBotMessages?.length > 1 ? (
                        <button
                          type="button"
                          onClick={() =>
                            handleDeleteInteraction(firstBotMessage?.position)
                          }
                          css={ButtonTrash}
                        >
                          <img src={ButtonDelete} alt="icon-button-delete" />
                        </button>
                      ) : null}
                    </div>
                  </>
                ))}
                <div className="flex justify-content-end">
                  <div>
                    <div css={Flex}>
                      <ImgIcon
                        onClick={() => {
                          dispatch(setEmojiModalOpen(true));
                        }}
                        className="tooltip-Smile"
                        data-pr-position="bottom"
                      >
                        <img src={Smile} alt="Icon-Smiles" />
                      </ImgIcon>
                      <Tooltip target=".tooltip-Smile">Enviar Emoji</Tooltip>
                      {/* <img src={Attachment} alt="Icon-Attachment" /> */}
                      {/* <img src={Link} alt="Icon-Link" /> */}
                      {/* <img src={MicBlue} alt="Icon-MicBlue" /> */}
                      {/* <img src={ThreePointsBlue} alt="Icon-ThreePointsBlue" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Token>
              <span
                className="p-float-label"
                style={{
                  width: "100%"
                }}
              >
                <Input
                  id="label"
                  value={valueToken}
                  style={{
                    borderRight: "none",
                    borderTopRightRadius: "0",
                    borderBottomRightRadius: "0"
                  }}
                />
                <label
                  style={{
                    marginTop: "0.46rem",
                    backgroundColor: "#FFF",
                    paddingLeft: "0.3rem",
                    paddingRight: "0.3rem"
                  }}
                  htmlFor="label"
                >
                  Token do Bot
                </label>
              </span>
              <div className="divCopy" onClick={handleCopy}>
                Copiar
              </div>
            </Token>
            <ContainerFields>
              <LabelContainerFields>
                <Text s="0.875rem" weight={400} lh="1.3125rem" color="#999999">
                  Habilitar campos
                </Text>
                <Span>?</Span>
              </LabelContainerFields>
              <div className="flex align-items-center gap-1 mb-2 ">
                <InputSwitch
                  id="name2"
                  height={10}
                  width={3}
                  // disabled={true}
                  checked={habilitarNome}
                  onChange={(e) => setHabilitarNome(e.value)}
                />
                <label htmlFor="name2" className="ml-2">
                  <Text lh="1.625rem">Nome</Text>
                </label>
              </div>
              <div className="flex align-items-center gap-1 mb-2 ">
                <InputSwitch
                  id="telefone"
                  height={10}
                  width={3}
                  // disabled={true}
                  checked={habilitarTelefone}
                  onChange={(e) => setHabilitarTelefone(e.value)}
                />
                <label htmlFor="telefone" className="ml-2">
                  <Text lh="1.625rem">Telefone</Text>
                </label>
              </div>
              <div className="flex align-items-center gap-1 mb-2 ">
                <InputSwitch
                  id="email"
                  height={10}
                  width={3}
                  // disabled={true}
                  checked={habilitarEmail}
                  onChange={(e) => setHabilitarEmail(e.value)}
                />
                <label htmlFor="email" className="ml-2">
                  <Text lh="1.625rem">E-mail</Text>
                </label>
              </div>
            </ContainerFields>
            <ButtonContainer>
              <Button
                onClick={handleSalvar}
                style={{
                  width: "calc(100% - 1rem)",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#02CDAD"
                }}
              >
                Salvar
              </Button>
            </ButtonContainer>
          </ContainerLeftModal>

          <ContainerRightModal>
            <div className="flex align-items-center justify-content-center">
              <div className="ml-3">
                <Text s="15px" lh="20px" weight={500} color="#FFF">
                  Personalizar cores e Avatar
                </Text>
              </div>
            </div>
            <CardCenter>
              <CardRoundedAvatar>
                <CardContentAvatar>
                  <img src={Camera} alt="Camera Icon" />
                  <div className="mt-1">
                    <Text color="#544F77" s="12px" weight={400}>
                      Avatar
                    </Text>
                  </div>
                </CardContentAvatar>
              </CardRoundedAvatar>
              <div className="mt-7 mb-2 flex align-items-center justify-content-evenly">
                <InputColor
                  color={avatarColor}
                  onChange={handleChangeAvatarColor}
                  label="Cor do Avatar"
                />
                <InputColor
                  color={chatColor}
                  onChange={handleChangeChatColor}
                  label="Cor do Chat"
                />
              </div>
              <Divisor />
              <div className="mt-2 mb-2 flex align-items-center justify-content-evenly">
                <ChatPreview chatColor={chatColor} botColor={avatarColor} />
                <BotPreview color={avatarColor} />
              </div>
              {/* <Divisor />
              <div className="flex align-items-center gap-3 mb-2 ml-3">
                <InputSwitch
                  height={10}
                  width={3}
                  // disabled={true}
                  checked={whatsappRedirect}
                  onChange={(e) => setWhatsappRedirect(e.value)}
                />
                <div className="ml-2">
                  <Text lh="1.625rem">
                    Habilitar direcionamento do Whatsapp
                  </Text>
                </div>
              </div> */}
            </CardCenter>
          </ContainerRightModal>
        </div>
      </Dialog>
    </div>
  );
};
