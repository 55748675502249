import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
import { AutomationConfig, bot } from "../../../../assets";
import { Text } from "../../../Text";
import { Container, ContainerIcon, Divisor } from "./styles";
import { useEffect, useState } from "react";
import WorkspaceUserService from "../../../../services/workspaceUserService";
import { AuthContextType, useAuth } from "../../../../auth/auth-provider";
import { InputTextarea } from "primereact/inputtextarea";
import { iBotAutomationDto } from "../../../../types/iBotResume";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { updateBotAutomation } from "../../../../redux/slices/bot";

const TimeRedoOptions = [
  { name: "Não refazer pergunta.", code: 0 },
  { name: "Refazer pergunta após 5min.", code: 5 },
  { name: "Refazer pergunta após 10min.", code: 10 },
  { name: "Refazer pergunta após 15min.", code: 15 },
  { name: "Refazer pergunta após 20min.", code: 20 },
  { name: "Refazer pergunta após 25min.", code: 25 },
  { name: "Refazer pergunta após 30min.", code: 30 },
  { name: "Refazer pergunta após 60min.", code: 60 }
];
const QuantidadeRedoOptions = [
  { name: "Até 1 vezes.", code: 1 },
  { name: "Até 2 vezes.", code: 2 },
  { name: "Até 3 vezes.", code: 3 },
  { name: "Até 4 vezes.", code: 4 },
  { name: "Até 5 vezes.", code: 5 }
];
const TimeTransferOptions = [
  { name: "Não transferir automaticamente.", code: 0 },
  { name: "Transferir após 15min.", code: 15 },
  { name: "Transferir após 30min.", code: 30 },
  { name: "Transferir após 60min.", code: 60 }
];
const typeTransferOnWorkspace = [
  { code: "USER", name: "Colaborador Específico" },
  { code: "2", name: "Sequencial" }
];
const FecharAutomationOptions = [
  { name: "Não fechar automaticamente.", code: 0 },
  { name: "Fechar sala após 15min.", code: 15 },
  { name: "Fechar sala após 30min.", code: 30 },
  { name: "Fechar sala após 60min.", code: 60 },
  { name: "Fechar sala após 12h.", code: 720 },
  { name: "Fechar sala após 24h.", code: 1440 }
];
const OnlyTransferredRooms = [
  { name: "Fechar todas.", code: "CLOSE_ALL" },
  { name: "Fechar somente transferidas.", code: "ONLY_TRANSFERRED" },
  { name: "Fechar somente não transferidas.", code: "ONLY_NOT_TRANSFERRED" }
];

export const BotAutomation: React.FC = () => {
  const { getUsersByWorkspace } = WorkspaceUserService();
  const { user } = useAuth() as AuthContextType;
  const { companyId } = user;
  const dispatch = useAppDispatch();
  const workspaces = user?.workspaces?.workspaces;
  const botAutomation = useAppSelector((state) => state.bot?.botAutomationDto);

  //automation default values
  const timeRedoValue = botAutomation?.botRepeatAutomationDto?.minutes ?? 0;
  const quantityRedoValue = botAutomation?.botRepeatAutomationDto?.times ?? 1;
  const [selectedCloseRoomOption, setSelectedCloseRoomOption] =
    useState<number>(0);
  const [selectedOnlyTransferredOption, setSelectedOnlyTransferredOption] =
    useState<string>(
      botAutomation?.botTransferAutomationDto?.queue ?? "ONLY_NOT_TRANSFERRED"
    );

  // Transfer Automation
  const [selectedTransferTypeOnWorkspace, setSelectedTransferTypeOnWorkspace] =
    useState<number>(0);

  const [usersFromWorksPaceSelected, setUsersFromWorksPaceSelected] = useState(
    []
  );

  // Functions
  useEffect(() => {
    const updateUsersList = async () => {
      if (Number(botAutomation?.botTransferAutomationDto?.workspaceId) > 0) {
        const retorno = await getUsersByWorkspace(
          companyId,
          botAutomation?.botTransferAutomationDto.workspaceId as number
        );
        setUsersFromWorksPaceSelected(retorno);
      }
    };
    updateUsersList();
  }, [
    botAutomation?.botTransferAutomationDto?.workspaceId,
    botAutomation?.botTransferAutomationDto?.userId
  ]);
  const handleSelectTypeTransferOnWorkspace = async (
    e: DropdownChangeParams
  ) => {
    setSelectedTransferTypeOnWorkspace(e.value);
    if (e.value === "1") {
      const retorno = await getUsersByWorkspace(companyId, e.value);
      setUsersFromWorksPaceSelected(retorno);
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            userId: 0,
            queue: e.value
          }
        })
      );
    } else {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            userId: 0,
            queue: e.value
          }
        })
      );
    }
  };

  const SetTimeTransferOptions = (e: DropdownChangeParams) => {
    const minutesValue = e.value !== null ? e.value : 0;
    const updatedBotCloseRoomAutomationDto =
      botAutomation?.botCloseRoomAutomationDto ?? {};

    if (minutesValue === 0) {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botCloseRoomAutomationDto: {
            ...updatedBotCloseRoomAutomationDto
          },
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            minutes: 0,
            callwebhook: false,
            workspaceId: 0,
            userId: 0,
            queue: "Não transferir automaticamente"
          }
        })
      );
    } else if (
      e.value !== 0 &&
      botAutomation?.botTransferAutomationDto.workspaceId === 0
    ) {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botCloseRoomAutomationDto: {
            ...updatedBotCloseRoomAutomationDto
          },
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            minutes: e.value as number,
            callwebhook: false,
            workspaceId: null
          }
        })
      );
    } else {
      dispatch(
        updateBotAutomation({
          ...(botAutomation as iBotAutomationDto),
          botCloseRoomAutomationDto: {
            ...updatedBotCloseRoomAutomationDto
          },
          botTransferAutomationDto: {
            ...botAutomation?.botTransferAutomationDto,
            minutes: e.value as number,
            callwebhook: false
          }
        })
      );
    }
  };

  const handleCloseRoomOptionChange = (e: DropdownChangeParams) => {
    const value = e.value !== null ? e.value : 0;
    setSelectedCloseRoomOption(value);

    dispatch(
      updateBotAutomation({
        ...(botAutomation as iBotAutomationDto),
        botCloseRoomAutomationDto: {
          ...botAutomation?.botCloseRoomAutomationDto,
          minutes: value
        }
      })
    );
  };

  const handleOnlyTransferredOptionChange = (e: DropdownChangeParams) => {
    const value = e.value !== null ? e.value : "ONLY_NOT_TRANSFERRED";
    setSelectedOnlyTransferredOption(value);

    dispatch(
      updateBotAutomation({
        ...(botAutomation as iBotAutomationDto),
        botTransferAutomationDto: {
          ...botAutomation?.botTransferAutomationDto,
          queue: value
        }
      })
    );
  };

  return (
    <Container>
      <div className="flex align-items-center justify-content-start gap-2 w-full">
        <ContainerIcon>
          <img src={AutomationConfig} alt="AutomationConfig" />
        </ContainerIcon>
        <Text s="1rem" weight={500} color="#0A043C">
          Automações
        </Text>
      </div>

      <Text
        style={{ textAlign: "left", width: "100%" }}
        s="0.875rem"
        weight={600}
        lh="3rem"
        color="#0A043C"
      >
        1. Refazer pergunta sem interação
      </Text>
      <Text
        style={{ textAlign: "left" }}
        s="0.75rem"
        weight={400}
        lh="1.3125rem"
        color="#0A043C"
      >
        Refaça uma pergunta sem interação após x minutos, vamos usar a última
        interação do bot para refazer a pergunta.
      </Text>
      <div className="flex flex-column align-items-center w-full">
        <Dropdown
          style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
          value={timeRedoValue}
          options={TimeRedoOptions}
          onChange={(e) =>
            dispatch(
              updateBotAutomation({
                ...(botAutomation as iBotAutomationDto),
                botRepeatAutomationDto: {
                  times: quantityRedoValue,
                  minutes: e?.value as number
                }
              })
            )
          }
          optionLabel="name"
          optionValue="code"
          placeholder="Refazer pergunta"
        />
        {(botAutomation?.botRepeatAutomationDto?.minutes || 0) > 0 && (
          <Dropdown
            style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
            value={quantityRedoValue}
            options={QuantidadeRedoOptions}
            onChange={(e) => {
              const value = e.value !== null ? e.value : 1;
              dispatch(
                updateBotAutomation({
                  ...(botAutomation as iBotAutomationDto),
                  botRepeatAutomationDto: {
                    times: value,
                    minutes: timeRedoValue
                  }
                })
              );
            }}
            optionLabel="name"
            optionValue="code"
            placeholder="Refazer pergunta"
          />
        )}
        <Divisor />
        <Text
          style={{ textAlign: "left", width: "100%" }}
          s="0.875rem"
          weight={600}
          lh="3rem"
          color="#0A043C"
        >
          2. Transferir salas sem interação
        </Text>
        <Text
          style={{ textAlign: "left" }}
          s="0.75rem"
          weight={400}
          lh="1.3125rem"
          color="#0A043C"
        >
          Transferir automaticamente salas sem atividade ou interação para uma
          plataforma externa, Workspace ou usuário específico após X minutos.
        </Text>
      </div>
      <div className="flex flex-column align-items-center w-full">
        <Dropdown
          style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
          value={botAutomation?.botTransferAutomationDto?.minutes ?? 0}
          options={TimeTransferOptions}
          onChange={(e) => SetTimeTransferOptions(e)}
          optionLabel="name"
          optionValue="code"
          placeholder="Transferir após"
        />

        {(botAutomation?.botTransferAutomationDto?.minutes || 0) > 0 ? (
          <>
            {Boolean(botAutomation?.botTransferAutomationDto?.callwebhook) ===
            false ? (
              <>
                <Dropdown
                  style={{ width: "100%", marginTop: "1rem" }}
                  value={botAutomation?.botTransferAutomationDto?.workspaceId}
                  options={workspaces}
                  onChange={(e) =>
                    dispatch(
                      updateBotAutomation({
                        ...(botAutomation as iBotAutomationDto),
                        botTransferAutomationDto: {
                          ...botAutomation?.botTransferAutomationDto,
                          workspaceId: e.value
                        }
                      })
                    )
                  }
                  optionLabel="name"
                  optionValue="id"
                  placeholder="Selecione um workspace"
                  showClear={false}
                  filter={true}
                />
                {botAutomation?.botTransferAutomationDto?.workspaceId > 0 ? (
                  <>
                    <Dropdown
                      style={{
                        width: "100%",
                        marginTop: "1rem",
                        lineHeight: "1.5rem"
                      }}
                      value={botAutomation?.botTransferAutomationDto?.queue}
                      options={typeTransferOnWorkspace}
                      onChange={(e) => handleSelectTypeTransferOnWorkspace(e)}
                      optionLabel="name"
                      optionValue="code"
                      placeholder="Selecione o tipo de transferência"
                    />
                    {botAutomation?.botTransferAutomationDto?.queue ===
                    "USER" ? (
                      <Dropdown
                        style={{
                          width: "100%",
                          marginTop: "1rem",
                          lineHeight: "1.5rem"
                        }}
                        value={botAutomation?.botTransferAutomationDto?.userId}
                        options={usersFromWorksPaceSelected}
                        onChange={(e) =>
                          dispatch(
                            updateBotAutomation({
                              ...(botAutomation as iBotAutomationDto),
                              botTransferAutomationDto: {
                                ...botAutomation?.botTransferAutomationDto,
                                userId: e.value
                              }
                            })
                          )
                        }
                        optionLabel="name"
                        optionValue="id"
                        placeholder="Selecione um colaborador"
                        showClear={false}
                        filter={true}
                      />
                    ) : null}
                  </>
                ) : null}
              </>
            ) : null}
          </>
        ) : null}
        <Divisor />

        <Text
          style={{ textAlign: "left", width: "100%" }}
          s="0.875rem"
          weight={600}
          lh="3rem"
          color="#0A043C"
        >
          3. Fechar salas sem interação
        </Text>
        <Text
          style={{ textAlign: "left", width: "100%" }}
          s="0.75rem"
          weight={400}
          lh="1.3125rem"
          color="#0A043C"
        >
          Fechar automaticamente salas sem atividade ou interação após X
          minutos.
        </Text>

        <Dropdown
          style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
          value={selectedCloseRoomOption}
          options={FecharAutomationOptions}
          onChange={handleCloseRoomOptionChange}
          optionLabel="name"
          optionValue="code"
          placeholder="Fechar salas após"
        />

        {(botAutomation?.botCloseRoomAutomationDto?.minutes || 0) > 0 && (
          <Dropdown
            style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
            value={selectedOnlyTransferredOption}
            options={OnlyTransferredRooms}
            onChange={handleOnlyTransferredOptionChange}
            optionLabel="name"
            optionValue="code"
            placeholder="Salas a serem transferidas"
          />
        )}

        {(botAutomation?.botCloseRoomAutomationDto?.minutes || 0) > 0 && (
          <InputTextarea
            style={{ width: "100%", marginTop: "1rem", lineHeight: "1.5rem" }}
            value={botAutomation?.botCloseRoomAutomationDto?.message}
            onChange={(e) =>
              dispatch(
                updateBotAutomation({
                  ...(botAutomation as iBotAutomationDto),
                  botCloseRoomAutomationDto: {
                    ...botAutomation?.botCloseRoomAutomationDto,
                    message: e.target.value,
                    minutes:
                      botAutomation?.botCloseRoomAutomationDto.minutes ?? 0,
                    active:
                      botAutomation?.botCloseRoomAutomationDto.active ?? false
                  }
                })
              )
            }
            rows={5}
            autoResize={true}
            maxLength={500}
            placeholder="Favor inserir uma mensagem para quando o usuário habilitar o fechamento automático das salas."
          />
        )}
      </div>
    </Container>
  );
};
