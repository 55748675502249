/* eslint-disable func-style */
import { useFetch2 } from "../util";

interface ITemplateList {
  offset: number;
  limit: number;
  workspaces: number[];
  name: string;
  companyId: number;
}
interface ICreateTemplate {
  botFlowId: number;
  botId: number;
  name: string;
  workspaces: number[];
}
interface IImportTemplate {
  botId: number;
  templateId: number;
}
function TemplatesService() {
  const request = useFetch2();

  const templatesList = async (data: ITemplateList) => {
    return request(`/api/templates/list`, { method: "POST" }, data);
  };

  const createTemplate = async (data: ICreateTemplate) => {
    return request(`/api/templates`, { method: "POST" }, data);
  };

  const getTemplateFlow = async (id: number) => {
    return request(`/api/templates/${id}/flow`, { method: "GET" });
  };

  const deleteTemplate = async (id: number) => {
    return request(`/api/templates/${id}`, { method: "DELETE" });
  };
  const importTemplate = async (data: IImportTemplate) => {
    return request(
      `/api/bots/${data.botId}/templates/${data.templateId}/import`,
      { method: "POST" }
    );
  };

  return {
    templatesList,
    createTemplate,
    getTemplateFlow,
    deleteTemplate,
    importTemplate
  };
}

export default TemplatesService;
