/* eslint-disable @typescript-eslint/no-explicit-any */
import { useFetch2 } from "../util";

export interface IFacebookLogin {
  code: string;
  botToken: string;
}
export interface IFacebookLoginRetorno {
  token: string;
}
export interface IGetFbPages {
  botId: number;
  type: "FACEBOOK" | "INSTAGRAM";
}
export interface IConnectDisconectFbPage {
  botId: string;
  pageId: string;
}
export interface IConnectFbLogin {
  code: string;
  botToken: string;
}
export interface IDisconnectFbUser {
  botId: number;
  userId: string;
}

const FacebookServices = () => {
  const fetch = useFetch2();
  const fbLogin = (code: string, botToken: string): Promise<IFacebookLogin> => {
    return fetch(
      `/api/bots/messenger/Oauth`,
      { method: "POST" },
      { code, botToken }
    );
  };
  const getFbPages = (data: IGetFbPages) => {
    const urlSerch = new URLSearchParams({
      type: data.type
    });
    return fetch(
      `/api/bots/${data.botId}/messenger/pages${
        data.type ? `?${urlSerch}` : ""
      }`,
      { method: "GET" }
    );
  };
  const connectFbPage = (data: IConnectDisconectFbPage) => {
    return fetch(
      `/api/bots/${data.botId}/messenger/account/${data.pageId}/subscribe`,
      { method: "POST" }
    );
  };
  const disconnectFbPage = (data: IConnectDisconectFbPage) => {
    return fetch(
      `/api/bots/${data.botId}/messenger/account/${data.pageId}/unsubscribe`,
      { method: "POST" }
    );
  };
  const disconnectMetaAccount = (data: IDisconnectFbUser) => {
    return fetch(`/api/bots/${data.botId}/messenger/${data.userId}`, {
      method: "DELETE"
    });
  };
  const connectFbLogin = (data: IConnectFbLogin) => {
    return fetch(`api/bots/messenger/Oauth`, { method: "POST" }, data);
  };
  const fbOauth = (code: string, botToken: string): Promise<IFacebookLogin> => {
    return fetch(
      `/api/bots/messenger/Oauth`,
      { method: "POST" },
      { code, botToken }
    );
  };
  const wppOauth = (code: string, botToken: string) => {
    return fetch(
      `/api/bots/whatsapp/Oauth`,
      { method: "POST" },
      { code, botToken }
    );
  };
  return {
    fbLogin,
    getFbPages,
    connectFbPage,
    disconnectFbPage,
    connectFbLogin,
    fbOauth,
    wppOauth,
    disconnectMetaAccount
  };
};

export default FacebookServices;
