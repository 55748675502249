import { Text } from "../../../../../components/Text/text";
import { FbWhite, MetaIcon } from "../../../../../assets";
import {
  Container,
  ButtonCopiar,
  ButtonSalvar,
  ErrorMessage,
  MyIconButton,
  Relative,
  WhatsappBusiness,
  MyLink
} from "./styles";
import { useEffect, useRef, useState } from "react";
import { InputSwitch } from "primereact/inputswitch";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  setBotActiveMeta,
  setBotPhone,
  setBotTokenMeta,
  setBotURLMeta,
  setBotBusinessPhoneId,
  setBotBusinessAccountId,
  setUpdateModalWhatsapp
} from "../../../../../redux/slices/bot";
import { css } from "@emotion/react";
import { Toast } from "primereact/toast";
import { Controller, useForm } from "react-hook-form";
import { InputText } from "../../../../../components";
import { v } from "../../../../../util";
import { zodResolver } from "@hookform/resolvers/zod";
import BotsServices from "../../../../../services/bots";
import { ProgressSpinner } from "primereact/progressspinner";
import { setToast } from "../../../../../redux/slices/user";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import FacebookServices from "../../../../../services/facebook";
import { useToast } from "../../../../../components/toast-provider";

interface ILeftContentWhatsappModal {
  show: (open: boolean) => void;
}

interface IPages {
  id: string;
  name: string;
  url: string;
  active: true;
  updatedAt: string;
}
interface retornoFbLogin {
  userName: string;
  userPictureUrl: string;
  id: string;
  pages: IPages[];
}

export const LeftContentWhatsappModal: React.FC<ILeftContentWhatsappModal> = ({
  show
}) => {
  const counterToast = useAppSelector(
    (state) => state.user.toastApp?.count || 0
  );
  const schema = v.object({
    phone: v.string().superRefine(v.nonempty()),
    token: v.string().superRefine(v.nonempty()),
    metaUrl: v.string().superRefine(v.nonempty()).superRefine(v.url()),
    businessPhoneId: v.string().superRefine(v.nonempty()),
    businessAccountId: v.string().superRefine(v.nonempty()),
    on: v.boolean()
  });
  const botResumeWhats = useAppSelector((state) => state.bot.whats);
  const [loading, setLoading] = useState(false);
  const botId = useAppSelector((state) => state.bot.id);
  const updateModalWhatsapp = useAppSelector(
    (state) => state.bot.updateModalWhatsapp
  );
  const [showButtonDesconect, setShowButtonDesconect] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm({
    defaultValues: {
      phone: "",
      token: "",
      metaUrl: "",
      businessPhoneId: "",
      businessAccountId: "",
      on: false
    },
    reValidateMode: "onSubmit",
    resolver: zodResolver(schema)
  });
  const { UpdateWhatsapp, GetWhatsappBusiness, disconnectWhatsappAccount } =
    BotsServices();
  const dispatch = useAppDispatch();
  const toastRef = useRef<Toast>(null);
  const toast = useToast();
  const { getValues } = useForm();

  const [showButtonFacebook, setShowButtonFacebook] = useState(false);
  const [checkStorage, setCheckStorage] = useState(false);
  const [baseURL, setBaseURL] = useState("");
  const botTokenActive = useAppSelector(
    (state) => state.flowInteractions.tokenActive
  );
  const botIdActive = useAppSelector(
    (state) => state.flowInteractions.botIdActive
  );
  const [childWindow, setChildWindow] = useState(null);
  const [fbPagesLogin, setFbPagesLogin] = useState<retornoFbLogin>(
    {} as retornoFbLogin
  );
  const { getFbPages, connectFbPage, disconnectFbPage, disconnectMetaAccount } =
    FacebookServices();

  const checkFbAuthentication = async () => {
    try {
      if (botIdActive > 0) {
        const retorno = await getFbPages({
          botId: botIdActive,
          type: "FACEBOOK"
        });
        setFbPagesLogin(retorno);
        setLoading(false);
      }
    } catch (error) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro.",
        detail: `Conexão não Não foi possível buscar os dados neste momento! Por favor, tente mais tarde. ${JSON.stringify(
          error
        )}`,
        sticky: false
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    setBaseURL(`${window.location.origin.toString()}`);
    checkFbAuthentication();
  }, [botIdActive]);

  useEffect(() => {
    const fetchWhatsappBusiness = async () => {
      const response = await GetWhatsappBusiness(botId);
      const { accounts, phone, token, url, phoneId, accountId } =
        response || {};

      if (accounts) {
        setShowButtonFacebook(false);
        setShowButtonDesconect(true);
        setAccounts(
          accounts.map((account: any) => ({
            ...account,
            label: `${account.phone} - ${account.name}`,
            value: account
          }))
        );

        const matchedAccount = accounts.find((acc) => acc.phone === phone);

        if (matchedAccount) {
          setSelectedAccount(matchedAccount);
          setValue("phone", matchedAccount.phone);
          setValue("token", matchedAccount.token || token || "");
          setValue("metaUrl", matchedAccount.url || url || "");
          setValue(
            "businessPhoneId",
            matchedAccount.whastappBusinessPhoneId || phoneId || ""
          );
          setValue(
            "businessAccountId",
            matchedAccount.whastappBusinessAccountId || accountId || ""
          );
        } else {
          setSelectedAccount(null);
          setValue("phone", "");
          setValue("token", "");
          setValue("metaUrl", "");
          setValue("businessPhoneId", "");
          setValue("businessAccountId", "");
        }
      } else {
        setShowButtonFacebook(true);
        setShowButtonDesconect(false);
        setValue("phone", phone || "");
        setValue("token", token || "");
        setValue("metaUrl", url || "");
        setValue("businessPhoneId", phoneId || "");
        setValue("businessAccountId", accountId || "");
      }
    };

    fetchWhatsappBusiness();
  }, [botId, updateModalWhatsapp]);

  const changeAccount = (e: any) => {
    const selectedAccount = e.value;
    setSelectedAccount(selectedAccount);

    if (selectedAccount) {
      setValue("phone", selectedAccount.phone || "");
      setValue("token", selectedAccount.token || "");
      setValue("metaUrl", selectedAccount.url || "");
      setValue(
        "businessPhoneId",
        selectedAccount.whastappBusinessPhoneId || ""
      );
      setValue(
        "businessAccountId",
        selectedAccount.whastappBusinessAccountId || ""
      );
    }
  };

  const disconnectUserWhatsapp = async () => {
    setLoading(true);
    const values = getValues();

    try {
      await disconnectWhatsappAccount(botId, {
        phone: values.phone,
        token: values.token,
        metaUrl: values.metaUrl,
        businessPhoneId: values.businessPhoneId,
        businessAccountId: values.businessAccountId
      });
    } catch (e: any) {
      toast?.current?.show({
        life: 5000,
        severity: "error",
        summary: "Erro.",
        detail: `Erro ao desconectar-se da conta neste momento! Por favor, tente mais tarde. ${JSON.stringify(
          e.description
        )}`,
        sticky: false
      });
    } finally {
      setLoading(false);
      dispatch(setUpdateModalWhatsapp(!updateModalWhatsapp));
    }
  };

  const openChildWindow = () => {
    const newWindow = window.open(
      `https://www.facebook.com/v18.0/dialog/oauth?client_id=${process.env.WHATSAPP_APP_ID}&redirect_uri=${baseURL}/whatsapp/Oauth&state=${botTokenActive}&response_type=code&scope=business_management,whatsapp_business_management`,
      "_blank"
    );
    setChildWindow(newWindow);
  };

  useEffect(() => {
    if (childWindow) {
      const timer = setInterval(() => {
        if (childWindow.closed) {
          clearInterval(timer);
          dispatch(setUpdateModalWhatsapp(!updateModalWhatsapp));
          toast?.current?.show({
            life: 5000,
            severity: "success",
            summary: "Autenticação realizada com sucesso!",
            detail: "Escolha seu número de telefone e salve as informações.",
            sticky: false
          });
        }
      }, 500);

      return () => clearInterval(timer);
    }
  }, [childWindow]);

  return (
    <Container>
      <Toast ref={toastRef} />
      <div className="flex">
        <MyIconButton>
          <img src={MetaIcon} alt="Meta-Icon" />
        </MyIconButton>
        <div className="ml-3">
          <Text
            fontSize="0.9375rem"
            fontWeight={600}
            lineHeight="1.25rem"
            color="#0A043C"
          >
            Integração com o WhatsApp
          </Text>
          <Text
            fontSize="0.75rem"
            fontWeight={400}
            lineHeight="1.125rem"
            color="#544F77"
          >
            Integre o seu número de Whatsapp com a Meta Business Suite.
          </Text>
        </div>
      </div>
      <form
        onSubmit={handleSubmit(async (d) => {
          setLoading(true);
          await UpdateWhatsapp(botId, {
            phone: d.phone,
            token: d.token,
            metaUrl: d.metaUrl,
            businessPhoneId: d.businessPhoneId,
            businessAccountId: d.businessAccountId,
            active: d.on
          })
            .then(() => {
              dispatch(setBotPhone(d.phone));
              dispatch(setBotTokenMeta(d.token));
              dispatch(setBotURLMeta(d.metaUrl));
              dispatch(setBotBusinessPhoneId(d.businessPhoneId));
              dispatch(setBotBusinessAccountId(d.businessAccountId));
              dispatch(setBotActiveMeta(d.on));
              dispatch(
                setToast({
                  count: counterToast + 1,
                  show: true,
                  life: 3000,
                  severity: "success",
                  summary: "Sucesso.",
                  detail: "Dados Whatsapp atualizados.",
                  sticky: false
                })
              );
              setLoading(false);
              show(false);
            })
            .catch(() => {
              setLoading(false);
              dispatch(
                setToast({
                  count: counterToast + 1,
                  show: true,
                  severity: "error",
                  summary: "Erro ao gravar dados Whatsapp.",
                  detail: "Favor tentar novamente mais tarde.",
                  life: 3000
                })
              );
            });
        })}
      >
        <WhatsappBusiness>
          {showButtonFacebook ? (
            <MyLink onClick={openChildWindow} rel="noreferrer">
              <img src={FbWhite} alt="facebook" />
              Ir para o Facebook
            </MyLink>
          ) : (
            <div className="selectItem">
              <Dropdown
                style={{ width: "100%" }}
                value={selectedAccount}
                options={accounts}
                onChange={changeAccount}
                optionLabel="label"
                placeholder="Selecione uma conta"
              />
            </div>
          )}
        </WhatsappBusiness>
        <div className="mt-4 w-full gap-5 flex flex-column align-items-center justify-content-start w-full">
          <div className="w-full">
            <div
              style={{ width: "inherit", marginTop: "1rem" }}
              className="p-float-label"
            >
              <Controller
                name="phone"
                control={control}
                render={({ field }) => (
                  <InputText
                    {...field}
                    id="phone"
                    type="text"
                    // autoClear={false}
                    // mask={/^\(\d{2}\) 9/ugmi.test(field.value) ? "(99) 99999-9999" : "(99) 9999 999 9999"}
                    // placeholder={/^\(\d{2}\) 9/ugmi.test(field.value) ? "(99) 99999-9999" : "(99) 9999 999 9999"}
                    // slotChar="_"
                  />
                )}
              />
              <label htmlFor="phone">Número de telefone</label>
            </div>
            {errors?.phone && (
              <ErrorMessage>{errors.phone?.message}</ErrorMessage>
            )}
          </div>
          <div className="w-full">
            <div style={{ width: "inherit" }} className="p-float-label">
              <Controller
                name="token"
                control={control}
                render={({ field }) => <InputText {...field} id="token" />}
              />
              <label htmlFor="token">Token</label>
            </div>
            {errors?.token && (
              <ErrorMessage>{errors.token?.message}</ErrorMessage>
            )}
          </div>
          <div className="w-full">
            <div style={{ width: "inherit" }} className="p-float-label">
              <Controller
                name="metaUrl"
                control={control}
                render={({ field }) => <InputText {...field} id="host" />}
              />
              <label htmlFor="host">URL Meta</label>
            </div>
          </div>
          <div className="w-full">
            <div style={{ width: "inherit" }} className="p-float-label">
              <Controller
                name="businessPhoneId"
                control={control}
                render={({ field }) => (
                  <InputText {...field} id="businessPhoneId" />
                )}
              />
              <label htmlFor="businessPhoneId">
                Identificação do número de telefone
              </label>
            </div>
          </div>
          <div className="w-full">
            <div style={{ width: "inherit" }} className="p-float-label">
              <Controller
                name="businessAccountId"
                control={control}
                render={({ field }) => (
                  <InputText {...field} id="businessAccountId" />
                )}
              />
              <label htmlFor="businessAccountId">
                Identificação da conta do WhatsApp Business
              </label>
            </div>
          </div>
          <Relative>
            <div className="relative w-full">
              <div style={{ width: "inherit" }} className="p-float-label">
                <InputText
                  disabled={true}
                  value={botResumeWhats?.urlWebhook}
                  style={{ paddingRight: "3rem" }}
                />

                <label htmlFor="host1">URL WebHook</label>
              </div>
            </div>
            <ButtonCopiar
              onClick={() => {
                navigator.clipboard.writeText(botResumeWhats?.urlWebhook);
                toastRef.current?.show({
                  severity: "success",
                  detail: "URL Webhook copiado com sucesso!"
                });
              }}
            >
              Copiar
            </ButtonCopiar>
          </Relative>

          <div className="flex align-items-center">
            <Text
              fontSize="0.875rem"
              fontWeight={400}
              lineHeight="1.3125rem"
              color="#6D6F6F"
            >
              Ambiente:
            </Text>
            <Text
              style={{ marginLeft: "0.5rem" }}
              fontSize="0.875rem"
              fontWeight={600}
              lineHeight="1.3125rem"
              color="#046AF3"
            >
              Produção
            </Text>
            <div className="ml-4 flex align-items-center">
              <Controller
                name="on"
                control={control}
                render={({ field: { onChange } }) => (
                  <InputSwitch
                    checked={botResumeWhats.on}
                    onChange={(e) => {
                      onChange(e.value);
                      dispatch(setBotActiveMeta(e.value));
                    }}
                  />
                )}
              />
              <div className="ml-2">
                <Text
                  fontSize="0.875rem"
                  fontWeight={400}
                  lineHeight="1.3125rem"
                  color="#6D6F6F"
                >
                  {`${botResumeWhats?.on ? "Ativo" : "Inativo"}`}
                </Text>
              </div>
            </div>
          </div>
        </div>
        <div
          className="flex justify-content-center align-items-center"
          style={{ height: "5rem", gap: "3rem" }}
        >
          {showButtonDesconect && (
            <Button
              onClick={disconnectUserWhatsapp}
              label="Desconectar"
              className="p-button-outlined p-button-danger"
              style={{ width: "170px", height: "42px" }}
            />
          )}

          <ButtonSalvar disabled={loading}>
            {loading && (
              <ProgressSpinner
                style={{ width: "16px", height: "16px", margin: "0.2rem" }}
                strokeWidth="8"
                fill="var(--surface-ground)"
                animationDuration=".5s"
              />
            )}
            Salvar
          </ButtonSalvar>
        </div>
      </form>
    </Container>
  );
};
