import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const ButtonSquared = css`
  border: 1px solid #d8e9f6;
  width: 2rem;
  height: 2rem;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: transparent;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
`;

export const FlexEnd = css`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const Flex = css`
  width: 50%;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-right: 1rem;
  justify-content: space-between;
`;

export const ImgIcon = styled.button`
  border: none;
  background-color: transparent;
  :hover {
    cursor: pointer;
  }
`;

export const ContentInteraction = styled.div`
  .dragging {
    opacity: 0.5;
    transform: scale(1.05);
    transition: all 0.2s ease;
    z-index: 1;
  }
`;
