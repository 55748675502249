import { userList } from "../../assets";
import { css } from "@emotion/react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "..";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import * as comunCss from "../comum_css";
import useCompany from "./use-company";
import EditCompany from "../EditCompany";
import {
  ButtonCreate,
  Header,
  Content,
  ContainerPage,
  Container,
  ContainerLeft
} from "./styles";
import { Identification } from "../header/components/Identification";

const Company = () => {
  const { findCompany } = useCompany();

  const [tablePropsData, setTablePropsData] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visible, setVisible] = useState(false);
  const [valueId, setValueId] = useState(0);

  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [loading, setLoading] = useState(false);

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 20,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: "", matchMode: "contains" }
    }
  });

  useEffect(() => {
    fetchCompanies();
  }, [first, rows, visible, globalFilterValue]);

  const onPage = (event: any) => {
    setFirst(event.first);
    setRows(event.rows);
    setLazyParams({
      ...lazyParams,
      first: event.first,
      rows: event.rows
    });
  };

  const fetchCompanies = async () => {
    setLoading(true);

    const { result, total } = await findCompany({
      offset: lazyParams.first,
      limit: lazyParams.rows,
      name: globalFilterValue
    });
    const tableProps = {
      first: lazyParams.first,
      lazy: true,
      onPage,
      paginator: true,
      rows: lazyParams.rows,
      totalRecords: total,
      value: result
    };
    setTablePropsData(tableProps);
    setLoading(false);
  };

  return (
    <ContainerPage>
      <Container>
        <ContainerLeft>
          <Identification />
        </ContainerLeft>

        <div
          css={css`
            @media (max-width: 500px) {
              display: none;
            }
          `}
        >
          <ButtonCreate
            onClick={() => {
              setVisible(true);
              setValueId(0);
            }}
          >
            <i className="pi pi-plus-circle" />
            Adicionar
          </ButtonCreate>
        </div>
      </Container>

      <div>
        <div className="p-5 gap-2">
          <Header>
            <div
              css={comunCss.TitleContent}
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <img src={userList} />
              Empresas
            </div>
          </Header>

          <Content>
            <div className="divTitle">Gerencie as empresas cadastradas.</div>

            <div className="divTitle">
              <div className="divFilter">
                <span className="p-input-icon-left">
                  <i className="pi pi-search" />
                  <InputText
                    value={globalFilterValue}
                    onChange={(e) => {
                      setGlobalFilterValue(e.target.value);
                      setFirst(0);
                    }}
                    id="name"
                    style={{
                      height: "100%",
                      width: "400px"
                    }}
                    placeholder="Buscar"
                  />
                </span>
              </div>

              <DataTable
                {...tablePropsData}
                globalFilter={globalFilterValue}
                filterDelay={600}
                className="custom-header"
                loading={loading}
                emptyMessage="Salas não encontradas."
                rows={rows}
              >
                <Column
                  field="id"
                  header="ID"
                  headerStyle={{ width: "50px" }}
                  alignHeader="center"
                  align="center"
                />
                <Column
                  field="name"
                  header="Empresa"
                  headerStyle={{ width: "250px" }}
                />
                <Column
                  field="plataformName"
                  header="Plataforma"
                  headerStyle={{ width: "420px" }}
                />

                <Column
                  field="active"
                  header="Ativo"
                  align="center"
                  alignHeader="center"
                  headerStyle={{ width: "10%" }}
                  body={(rowData) => (
                    <div
                      css={css`
                        display: flex;
                        justify-content: center;
                      `}
                    >
                      {rowData.active ? (
                        <div className="active">Ativo</div>
                      ) : (
                        <div className="noActive">Inativo</div>
                      )}
                    </div>
                  )}
                />

                <Column
                  align="center"
                  alignHeader="center"
                  headerStyle={{ width: "10%" }}
                  body={(rowData) => (
                    <div css={comunCss.dtDiv_bodt}>
                      <Button
                        label="Editar"
                        className="p-button-outlined"
                        onClick={() => {
                          setVisible(true);
                          setValueId(rowData.id);
                        }}
                        css={comunCss.buttonCustom}
                      />
                    </div>
                  )}
                />
              </DataTable>

              <EditCompany
                visible={visible}
                onHide={() => setVisible(false)}
                id={valueId}
              />
            </div>
          </Content>
        </div>
      </div>
    </ContainerPage>
  );
};

export default Company;
