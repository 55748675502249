import { LayoutHeader2 } from "../components/Interactions/LayoutHeader2";
import ContactsList from "../components/ContactsList";

export const Contacts: React.FC = () => {
  return (
    <LayoutHeader2>
      <ContactsList />
    </LayoutHeader2>
  );
};
