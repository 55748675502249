import {
  IBotInteractions,
  Workspace,
  Users,
  iBotAutomationDto
} from "./../../types/iBotResume.d";
import {
  IBotLastServices,
  IBotUpdate,
  IBotVersions,
  IWhats
} from "../../types/iBotResume";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: IBotUpdate = {
  id: 0,
  name: "",
  companyId: -1,
  workspaceId: -1,
  active: true,
  chatColor: "",
  avatarColor: "",
  interactions: [],
  nameOnStart: false,
  emailOnStart: false,
  phoneOnStart: false,
  whatsappRedirect: false,
  target: -1,
  token: "",
  activeBotFlowId: -1,
  services: 0,
  createdBy: "",
  createdAt: "2000-01-02",
  updatedAt: "2000-01-02",
  workspace: "",
  serviceHours: 0,
  buttonEditScript: false,
  //   image: "",
  key: "",
  whats: {
    id: -1,
    phone: "",
    urlMeta: "",
    tokenMeta: "",
    businessPhoneId: "",
    businessAccountId: "",
    urlWebhook: "",
    tokenWebhook: "",
    on: true
  },
  updateModalWhatsapp: false,
  defaulFallbackMessages: [""],
  versions: [],
  lastServices: [],
  toast: null,
  botTransferId: -1,
  botAutomationDto: {
    botRepeatAutomationDto: {
      minutes: 0,
      times: 0
    },
    botTransferAutomationDto: {
      minutes: 0,
      callwebhook: false,
      workspaceId: 0,
      userId: 0
    },
    botCloseRoomAutomationDto: {
      minutes: 0,
      message: "",
      closeRoomType: ""
    }
  } as iBotAutomationDto,
  workspaceList: []
};
const botSlice = createSlice({
  name: "bot",
  initialState,
  reducers: {
    updateBotAutomation(state, action: PayloadAction<iBotAutomationDto>) {
      state.botAutomationDto = action.payload;
    },
    setFirstBotInteractions(state, action: PayloadAction<IBotInteractions[]>) {
      state.interactions = action.payload;
    },
    setBotNameOnStart(state, action: PayloadAction<boolean>) {
      state.nameOnStart = action.payload;
    },
    setBotEmailOnStart(state, action: PayloadAction<boolean>) {
      state.emailOnStart = action.payload;
    },
    setBotPhoneOnStart(state, action: PayloadAction<boolean>) {
      state.phoneOnStart = action.payload;
    },
    setBotWhatsappRedirect(state, action: PayloadAction<boolean>) {
      state.whatsappRedirect = action.payload;
    },
    addBotFallBack(state) {
      state.defaulFallbackMessages = [...state.defaulFallbackMessages, ""];
    },
    deleteBotFallback(state, action: PayloadAction<number>) {
      state.defaulFallbackMessages = state.defaulFallbackMessages.filter(
        (msg, idx) => idx !== action.payload
      );
    },
    updateDefaultFallBackMessage(
      state,
      action: PayloadAction<{ newMessage: string; idx: number }>
    ) {
      state.defaulFallbackMessages = state.defaulFallbackMessages.map(
        (el, idx) => {
          if (action.payload.idx === idx) {
            return action.payload.newMessage;
          }
          return el;
        }
      );
    },
    setBotId(state, action: PayloadAction<number>) {
      state.id = action.payload;
    },
    setBotVersions(state, action: PayloadAction<IBotVersions[]>) {
      state.versions = action.payload;
    },
    setBotLastServices(state, action: PayloadAction<IBotLastServices[]>) {
      state.lastServices = action.payload;
    },
    setBotServices(state, action: PayloadAction<number>) {
      state.services = action.payload;
    },
    setName(state, action: PayloadAction<string>) {
      state.name = action.payload;
    },
    setBotActiveBotFlowId(state, action: PayloadAction<number>) {
      state.activeBotFlowId = action.payload;
    },
    setBotCreatedAt(state, action: PayloadAction<string>) {
      state.createdAt = action.payload;
    },
    setBotCreatedBy(state, action: PayloadAction<string>) {
      state.createdBy = action.payload;
    },
    setBotUpdateddAt(state, action: PayloadAction<string>) {
      state.updatedAt = action.payload;
    },
    setBotServiceHours(state, action: PayloadAction<number>) {
      state.serviceHours = action.payload;
    },
    setBotWorkspace(state, action: PayloadAction<string>) {
      state.workspace = action.payload;
    },
    setDefaultFallBackMessage(state, action: PayloadAction<string[]>) {
      const array = action.payload.length === 0 ? [""] : action.payload;
      state.defaulFallbackMessages = array;
    },
    setCompanyId(state, action: PayloadAction<number>) {
      state.companyId = action.payload || -1;
    },
    setWorkspaceId(state, action: PayloadAction<number>) {
      state.workspaceId = action.payload;
    },
    setActive(state, action: PayloadAction<boolean>) {
      state.active = action.payload;
    },
    setBotChatColor(state, action: PayloadAction<string>) {
      state.chatColor = action.payload;
    },
    setBotAvatarColor(state, action: PayloadAction<string>) {
      state.avatarColor = action.payload;
    },
    setTarget(state, action: PayloadAction<number>) {
      state.target = action.payload;
    },
    setToken(state, action: PayloadAction<string>) {
      state.token = action.payload;
    },
    // setImage(state, action: PayloadAction<string>) {
    //     state.image = action.payload;
    // },
    setKey(state, action: PayloadAction<string>) {
      state.key = action.payload;
    },
    setBotWhatsId(state, action: PayloadAction<number>) {
      state.whats.id = action.payload;
    },
    setBotPhone(state, action: PayloadAction<string>) {
      state.whats.phone = action.payload;
    },
    setBotURLMeta(state, action: PayloadAction<string>) {
      state.whats.urlMeta = action.payload;
    },
    setBotTokenMeta(state, action: PayloadAction<string>) {
      state.whats.tokenMeta = action.payload;
    },
    setBotBusinessPhoneId(state, action: PayloadAction<string>) {
      state.whats.businessPhoneId = action.payload;
    },
    setBotBusinessAccountId(state, action: PayloadAction<string>) {
      state.whats.businessAccountId = action.payload;
    },
    setBotURLWebhook(state, action: PayloadAction<string>) {
      state.whats.urlWebhook = action.payload;
    },
    setBotTokenWebhook(state, action: PayloadAction<string>) {
      state.whats.tokenWebhook = action.payload;
    },
    setBotActiveMeta(state, action: PayloadAction<boolean>) {
      state.whats.on = action.payload;
    },
    setBotWhatsObject(state, action: PayloadAction<IWhats>) {
      state.whats = action.payload;
    },
    setUpdateModalWhatsapp(state, action: PayloadAction<boolean>) {
      state.updateModalWhatsapp = action.payload;
    },
    setBotTransferId(state, action: PayloadAction<number>) {
      state.botTransferId = action.payload;
    },
    setWorkspaceList(state, action: PayloadAction<Workspace[]>) {
      state.workspaceList = action.payload;
    },
    setButtonEditScript(state, action: PayloadAction<boolean>) {
      state.buttonEditScript = action.payload;
    }
  }
});

export const {
  setFirstBotInteractions,
  setBotNameOnStart,
  setBotEmailOnStart,
  setBotPhoneOnStart,
  setBotWhatsappRedirect,
  setBotId,
  addBotFallBack,
  deleteBotFallback,
  updateDefaultFallBackMessage,
  setDefaultFallBackMessage,
  setName,
  setCompanyId,
  setWorkspaceId,
  setActive,
  setBotChatColor,
  setBotAvatarColor,
  setTarget,
  setToken,
  setBotServices,
  // setImage,
  setKey,
  setBotWhatsId,
  setBotPhone,
  setBotURLMeta,
  setBotTokenMeta,
  setBotURLWebhook,
  setBotTokenWebhook,
  setBotBusinessPhoneId,
  setBotBusinessAccountId,
  setBotActiveMeta,
  setBotWhatsObject,
  setUpdateModalWhatsapp,
  setBotActiveBotFlowId,
  setBotCreatedAt,
  setBotCreatedBy,
  setBotUpdateddAt,
  setBotWorkspace,
  setBotServiceHours,
  setBotVersions,
  setBotLastServices,
  setBotTransferId,
  updateBotAutomation,
  setWorkspaceList,
  setButtonEditScript
} = botSlice.actions;

export default botSlice.reducer;
