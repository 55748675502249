import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useToast } from "../components";

export default () => {
  const navigate = useNavigate();
  const toast = useToast();

  return useCallback(
    async <T>(url: string, init?: RequestInit, input?: T) => {
      const response = await fetch(url, {
        credentials: "same-origin",
        mode: "same-origin",
        headers: {
          "Content-Type": "application/json"
        },
        ...init,
        body: input ? JSON.stringify(input) : undefined
      });
      if (response.status <= 399) {
        return response.json();
      } else if (response.status >= 400 && response.status <= 499) {
        if (response.status === 401) {
          navigate("/login");
        } else {
          const errors = await response.json();
          toast.current?.show({
            severity: "error",
            content: errors.message
          });

          throw errors;
        }
      } else {
        const errors = await response.json();
        toast.current?.show({
          severity: "error",
          content: errors.message
        });
        throw errors;
      }
    },
    [navigate, toast]
  );
};
