import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const Label = css`
  position: relative;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #888888;
`;

export const Options = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  background-color: #fff;
  color: #4c5862;
`;

export const Modal = styled.dialog`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #0a043c;
  background-color: white;
  border-radius: 1rem;
  border: 1px solid #000;
  overflow-y: scrool;
  height: 600px;
  max-height: 73vh;
  width: 100%;
  z-index: 13;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-top: -200px;
  box-shadow: rgba(0, 0, 0, 0.3);
  overflow-y: scroll;
  /* position: relative; */

  .dragging {
    opacity: 0.5;
    transform: scale(1.05);
    transition: all 0.2s ease;
    z-index: 1;
    background: red;
  }
`;
export const Overlay = styled.div`
  position: absolute;
  z-index: 11;
  min-height: 100%;
  min-width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const Title = css`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #0a043c;
  margin: 0 auto;
`;

export const Span = styled.span`
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.875rem;
  color: #046af3;
  background-color: #d4e2f3;
  width: 1.0625rem;
  height: 1.0625rem;
  border-radius: 50%;
`;
export const VDivisor = css`
  border: 0.8px solid #d2d2d2;
  width: 2.8125rem;
  transform: rotate(90deg);
  position: absolute;
  top: 48%;
  right: 1.3rem;

  /* transform-origin: left top; */
`;
export const VDivisorLeft = css`
  border: 0.8px solid #d2d2d2;
  width: 2.8125rem;
  transform: rotate(90deg);
  position: absolute;
  top: 48%;
  right: 1.3rem;
  left: 1.3rem;
  /* transform-origin: left top; */
`;
export const Dica = styled.p`
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: left;
`;
export const Button = styled.button`
  margin-bottom: 1.5rem;
  display: flex;
  width: 100%;
  gap: 0.5rem;
  height: 2.8125rem;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #046af3;
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 8px;
  :hover {
    cursor: pointer;
    background-color: #d2d2d2;
  }
`;

export const ButtonRemove = styled.button`
  background: #ffffff;
  border: 1px solid #ff5a5a;
  width: 50%;
  height: 2.625rem;
  /* accent button shadow */
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 8px;
  :hover {
    cursor: pointer;
    background-color: #d2d2d2;
  }
`;

export const ButtonSave = styled.button`
  background: #ffffff;
  border: 1px solid #046af3;
  width: 50%;
  height: 2.625rem;
  /* accent button shadow */
  box-shadow: 0px 4px 10px rgba(16, 156, 241, 0.24);
  border-radius: 8px;
  :hover {
    cursor: pointer;
    background-color: #d2d2d2;
  }
`;
