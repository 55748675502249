import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  toggleTransferModal,
  setNewValueDialogId
} from "../../../redux/slices/flowInteractions";
import {
  BoxAgentNotice,
  ContainerButtonEnd,
  ContainerContent,
  ContainerHeader,
  Divisor,
  LabelBlue,
  Modal,
  MyImage,
  Overlay,
  SaveButton,
  Span
} from "./styles";
import { Check } from "../../../assets";
import { Text } from "../../../components/Text";
import Dropdown from "../../../components/dropdown";
import WorkspaceUserService from "../../../services/workspaceUserService";
import { DropdownChangeParams } from "primereact/dropdown";
import { AuthContextType, useAuth } from "../../../auth/auth-provider";
// import { TreeSelect, TreeSelectSelectionKeys } from "primereact/treeselect";
import { setUsersFromWorkspaces } from "../../../redux/slices/workspaces";
import { Checkbox } from "primereact/checkbox";
import OmnichannelService from "../../../services/omnichannelService";
import { useNavigate } from "react-router-dom";

export const TransferModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const redirect = useNavigate();
  const { user } = useAuth() as AuthContextType;
  const { companyId } = user;
  const { getUsersByWorkspace } = WorkspaceUserService();
  const transferModalOpen = useAppSelector(
    (state) => state.flowInteractions.transferModal
  );
  const dialogIdActive = useAppSelector(
    (state) => state.flowInteractions.dialogActive
  );
  const { transferOminichannelDialog } = OmnichannelService();
  const toast = useRef<Toast>(null);
  // old Way
  // const workspaces = useAppSelector((state) => state.workspaces);
  const workspaces = user?.workspaces?.workspaces;
  const [selectedWorkspace, setSelectedWorkspace] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });
  const [selectedTypeTransfer, setSelectedTypeTransfer] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });
  const [sendToThirdpartySystem, setSendToThirdpartySystem] = useState(false);
  const [selectedUser, setSelectedUser] = useState<{
    id: number;
    name: string;
  }>({ id: -1, name: "" });

  const typeTransfer = [
    { id: 1, name: "Colaborador Específico" },
    { id: 2, name: "Sequencial" }
  ];
  const [usersFromWorksPaceSelected, setUsersFromWorksPaceSelected] = useState(
    []
  );
  useEffect(() => {
    const fetchData = async () => {
      const userId = user?.id as number;
      const wsId = selectedWorkspace?.id as number;
      const wsName = workspaces?.filter((el) => el.id === wsId)[0]?.name;
      // setJustOnlineUsers(nodeSelected?.data?.transferData?.onlyOnlineUser as boolean);
      setSelectedWorkspace({ id: wsId, name: wsName as string });
      setSelectedTypeTransfer({ id: 1, name: "Colaborador Específico" });
      // setUserNameColabEspecifico(nodeSelected?.data?.transferData?.userName as string);
      if (wsId) {
        const retorno = await getUsersByWorkspace(companyId, wsId);
        dispatch(setUsersFromWorkspaces(retorno));
        const labelLocal = retorno?.filter(
          (el: { id: number }) => el?.id === userId
        )[0]?.name as string;
        setUsersFromWorksPaceSelected(retorno);
        setSelectedUser({ id: userId, name: labelLocal });
      } else {
        setSelectedWorkspace({ id: -1, name: "" });
        setSelectedTypeTransfer({ id: -1, name: "" });
        setSelectedUser({ id: -1, name: "" });
      }
    };
    if (selectedTypeTransfer.id > 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferModalOpen]);

  const handleSave = async () => {
    try {
      await transferOminichannelDialog({
        dialogId: dialogIdActive,
        transferToThirdPartySystem: sendToThirdpartySystem,
        userId: selectedUser.id,
        workspaceId: selectedWorkspace.id
      });
      setSelectedWorkspace({ id: -1, name: "" });
      setSelectedTypeTransfer({ id: -1, name: "" });
      setSelectedUser({ id: -1, name: "" });
      toast.current?.show({
        life: 3000,
        severity: "success",
        summary: "Salas transferida.",
        detail: "Sala transferida com sucesso."
      });
      dispatch(toggleTransferModal());
      dispatch(setNewValueDialogId(selectedUser.id));
      redirect(`/omnichannel/`);
    } catch (error) {
      toast.current?.show({
        life: 3000,
        severity: "error",
        summary: "Conversa não transferidas.",
        detail:
          "Não foi possível transferir a sala de conversa. Por favor, tente novamente mais tarde."
      });
    }
  };

  const handleSelectedTypeTransfer = (e: DropdownChangeParams) => {
    if (e.value.id === 2 && selectedUser.id !== -1) {
      setSelectedUser({ id: -1, name: "" });
    }
    setSelectedTypeTransfer(e.value);
  };

  const handleSelectedWorkspace = async (e: DropdownChangeParams) => {
    const companyData = localStorage.getItem("company");
    const workspaceId = e.value.id;
    let valueCompanyId = companyId;

    if (user.admin && companyData) {
      const company = JSON.parse(companyData);
      valueCompanyId = company.id;
    }

    setSelectedWorkspace(e.value);
    const retorno = await getUsersByWorkspace(valueCompanyId, workspaceId);
    setUsersFromWorksPaceSelected(retorno);
  };

  const handleAskSave = () => {
    handleSave();
  };

  const deleteModalInformation = () => {
    setSelectedWorkspace({ id: -1, name: "" });
    setSelectedTypeTransfer({ id: -1, name: "" });
    setSelectedUser({ id: -1, name: "" });
    setSendToThirdpartySystem(false);
  };

  return (
    <>
      <div
        style={{ display: transferModalOpen ? "block" : "none" }}
        css={Overlay}
      />

      <dialog
        style={{ display: transferModalOpen ? "flex" : "none" }}
        open={transferModalOpen}
        css={Modal}
      >
        <div className="flex flex-column relative h-full w-full">
          <ContainerHeader>
            <Toast
              ref={toast}
              style={{ position: "sticky", top: 0, zIndex: 15 }}
            />
            <div className="flex w-full align-items-center justify-content-end">
              <Button
                style={{ width: "2rem", height: "2rem", marginLeft: "auto" }}
                onClick={() => {
                  dispatch(toggleTransferModal());
                  deleteModalInformation();
                }}
                icon="pi pi-times"
                className="p-button-rounded p-button-danger p-button-text p-button-sm ml-auto"
                aria-label="Cancel"
              />
            </div>

            <div className="flex align-items-center justify-content-center w-full">
              <Text s="0.9375rem" weight={600} lh="1.3125rem" color="#0A043C">
                Transferir atendimento?
              </Text>
              <Span>?</Span>
            </div>
            <div>
              <div className="flex mt-2 w-full justify-content-center align-items-center">
                <Text s="0.875rem" weight={400} lh="1.3125rem" color="#4C5862">
                  Informe como deseja distribuir as
                </Text>
              </div>
            </div>
            <div>
              <div className="flex mt-2 w-full justify-content-center align-items-center">
                <Text
                  style={{ textAlign: "center" }}
                  s="0.875rem"
                  weight={400}
                  lh="1.3125rem"
                  color="#4C5862"
                >
                  oportunidades deste atendimento
                </Text>
              </div>
            </div>
          </ContainerHeader>
          <ContainerContent>
            <Dropdown
              style={{ width: "100%", marginTop: "1rem" }}
              value={selectedWorkspace}
              options={workspaces}
              onChange={(e) => handleSelectedWorkspace(e)}
              optionLabel="name"
              placeholder="Selecione um workspace"
              filter={true}
            />

            {selectedWorkspace.id > 0 && (
              <Dropdown
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  lineHeight: "1.3125rem"
                }}
                value={selectedTypeTransfer}
                options={typeTransfer}
                onChange={(e) => handleSelectedTypeTransfer(e)}
                optionLabel="name"
                placeholder="Selecione o tipo de transferência"
              />
            )}

            {selectedWorkspace.id > 0 && selectedTypeTransfer.id === 1 ? (
              <Dropdown
                style={{
                  width: "100%",
                  marginTop: "1rem",
                  lineHeight: "1.3125rem"
                }}
                value={selectedUser}
                options={usersFromWorksPaceSelected}
                onChange={(e) => setSelectedUser(e.value)}
                optionLabel="name"
                placeholder="Selecione um colaborador"
                filter={true}
                // valueTemplate={selectedStateTemplate}
                // itemTemplate={stateOptionTemplate}
              />
            ) : null}
            {(selectedWorkspace.id > 0 && selectedTypeTransfer.id === 2) ||
            (selectedWorkspace.id > 0 &&
              selectedTypeTransfer.id === 1 &&
              selectedUser.id > 0) ? (
              <div className="flex gap-3 mt-3">
                <Checkbox
                  id="id_atendente"
                  checked={sendToThirdpartySystem}
                  onChange={(e) => setSendToThirdpartySystem(e.checked)}
                />
                <LabelBlue htmlFor="id_atendente" className="p-checkbox-label">
                  Transferir para plataforma Externa
                </LabelBlue>
              </div>
            ) : null}

            {selectedWorkspace.id > 0 && selectedTypeTransfer.id === 2 ? (
              <BoxAgentNotice>
                <i className="pi pi-info-circle" />
                <Text
                  style={{ textAlign: "left" }}
                  s="0.875rem"
                  lh="1.125rem"
                  color="#000000"
                >
                  Cada agente vai receber a oportunidade de forma sequencial de
                  1 para 1.
                </Text>
              </BoxAgentNotice>
            ) : null}
          </ContainerContent>

          <ContainerButtonEnd>
            <Divisor />
            <SaveButton
              disabled={
                selectedWorkspace.id === -1 ||
                (selectedWorkspace.id > 0 && selectedTypeTransfer.id < 1) ||
                (selectedWorkspace.id > 1 &&
                  selectedTypeTransfer.id === 1 &&
                  selectedUser.id === -1)
              }
              onClick={handleAskSave}
            >
              <MyImage
                disabled={
                  selectedWorkspace.id === -1 ||
                  (selectedWorkspace.id > 0 && selectedTypeTransfer.id < 1) ||
                  (selectedWorkspace.id > 1 &&
                    selectedTypeTransfer.id === 1 &&
                    selectedUser.id === -1)
                }
                src={Check}
                alt="Check-Button"
              />
              Transferir
            </SaveButton>
          </ContainerButtonEnd>
        </div>
      </dialog>
    </>
  );
};
